/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Dashboards
05.Pages
06.Applications
  06.01.Chat
  06.02.Survey
  06.03.Todo
07.UI
  07.01.Alerts
  07.02.Badges
  07.03.Buttons
  07.04.Cards
  07.05.Carousel
  07.06.Charts
  07.07.Collapse
  07.08.Dropdowns
  07.09.Editors
  07.10.Forms
  07.11.Form Components
  07.12.Icons
  07.13.Input Groups
  07.14.Jumbotron
  07.15.Modal
  07.16.Navigation
  07.17.Popover & Tooltip
  07.18.Sortable
  07.19.Maps
  07.20.Tables
  07.21.Wizards
*/

module.exports = {
  APROVED: 'Aprovado',
  PENDING: 'Pendente',
  CANCELED: 'Cancelado',
  DONE: 'Pago',
  PROCESSING: 'Processando',
  active: 'Ativo',
  inactive: 'Inativo',
  deleted: 'Removido',
  'User already exists.': 'Este usuário já foi cadastrado.',
  'Email already exists.': 'Este e-mail já foi cadastrado.',
  'Vehicle already exists.': 'Este veículo já foi cadastrado.',
  'Container already exists.': 'Este recipiente já foi cadastrado.',
  'Recipient already exists.': 'Este beneficiário já foi cadastrado.',
  'Driver already exists.': 'Este condutor já foi cadastrado.',
  /* 01.General */
  'general.copyright': 'Gogo React © 2018 All Rights Reserved.',

  /* 02.User Login, Logout, Register */
  'user.login-title': 'Login',
  'user.register': 'Cadastrar',
  'user.forgot-password': 'Esqueci minha senha',
  'user.email': 'E-mail',
  'user.password': 'Senha',
  'user.forgot-password-question': 'Esqueceu sua senha?',
  'user.fullname': 'Full Name',
  'user.login-button': 'ENTRAR',
  'user.register-button': 'CADASTRAR',
  'user.reset-password-button': 'RECUPERAR',
  'user.buy': 'COMPRAR',
  'user.buy-credits': 'COMPRAR CRÉDITOS',
  'user.username': 'Usuário',
  'user.new-password-again': 'New Password Again',
  'user.new-password': 'New Password',
  'user.reset-password': 'Reset Password',
  'user.logout': 'Sair',

  /* 03.Menu */
  'menu.app': 'Home',
  'menu.dashboards': 'Dashboards',
  'menu.default': 'Default',
  'menu.analytics': 'Analytics',
  'menu.ecommerce': 'Ecommerce',
  'menu.content': 'Content',
  'menu.actions': 'Ações',
  'menu.entries': 'Cadastros',
  'menu.stations': 'Postos',
  'menu.station': 'Posto',
  'menu.map': 'Mapa',
  'menu.vehicle': 'Veículos',
  'menu.vehicles': 'Veículos',
  'menu.container': 'Recipientes',
  'menu.bills': 'Faturas',
  'menu.branches': 'Filiais',
  'menu.organizations': 'Empresas',
  'menu.admins': 'Administradores',
  'menu.super-admins': 'Super Administradores',
  'menu.driver': 'Condutores',
  'menu.user': 'Usuários',
  'menu.stationUsers': 'Usuários Posto',
  'menu.recipient': 'Beneficiários',
  'menu.transactions': 'Transações',
  'menu.frauds': 'Fraudes',
  'menu.fraud-suspicion': 'Suspeitas de Fraude',
  'menu.reports': 'Relatórios',
  'menu.deposits': 'Depósitos',
  'menu.transfer': 'Recebidos',
  'menu.money-transfer': 'Pagamentos',
  'menu.fill-report': 'Relatório - Abastecimentos',
  'menu.pages': 'Pages',
  'menu.data-list': 'Data List',
  'menu.thumb-list': 'Thumb List',
  'menu.image-list': 'Image List',
  'menu.details': 'Details',
  'menu.search': 'Buscar',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.tanks': 'Tanques',
  'menu.forgot-password': 'Forgot Password',
  'menu.error': 'Erro',
  'menu.applications': 'Applications',
  'menu.todo': 'To-do List',
  'menu.survey': 'Survey',
  'menu.chat': 'Chat',
  'menu.ui': 'UI',
  'menu.alerts': 'Alerts',
  'menu.badges': 'Badges',
  'menu.buttons': 'Buttons',
  'menu.cards': 'Cards',
  'menu.carousel': 'Carousel',
  'menu.charts': 'Charts',
  'menu.collapse': 'Collapse',
  'menu.dropdowns': 'Dropdowns',
  'menu.editors': 'Editors',
  'menu.form-layouts': 'Form Layouts',
  'menu.form-components': 'Form Components',
  'menu.form-validations': 'Form Validations',
  'menu.form-wizard': 'Form Wizard',
  'menu.icons': 'Icons',
  'menu.icon': 'Icon',
  'menu.input-groups': 'Input Groups',
  'menu.jumbotron': 'Jumbotron',
  'menu.modal': 'Modal',
  'menu.navigation': 'Navigation',
  'menu.popover-tooltip': 'Popover & Tooltip',
  'menu.sortable': 'Sortable',
  'menu.tables': 'Tables',
  'menu.menu': 'Menu',
  'menu.subhidden': 'Subhidden',
  'menu.hidden': 'Hidden',
  'menu.visible': 'Visible',
  'menu.maps': 'Maps',
  'menu.landingpage': 'Landing Page',
  'menu.multipage-home': 'Multipage Home',
  'menu.singlepage-home': 'Singlepage Home',
  'menu.about': 'About',
  'menu.auth-login': 'Auth Login',
  'menu.auth-register': 'Auth Register',
  'menu.reset-password': 'Reset Password',
  'menu.blog': 'Blog',
  'menu.blog-list': 'Blog List',
  'menu.blog-detail': 'Blog Detail',
  'menu.careers': 'Careers',
  'menu.confirmation': 'Confirmation',
  'menu.contact': 'Contact',
  'menu.docs': 'Docs',
  'menu.features': 'Features',
  'menu.prices': 'Prices',
  'menu.videos': 'Videos',
  'menu.mailing': 'Mailing',
  'menu.invoice': 'Fatura',
  'menu.blank-page': 'Blank Page',
  'menu.types': 'Menu Types',
  'menu.levels': 'Menu Levels',
  'menu.third-level-1': 'Third Level 1',
  'menu.third-level-2': 'Third Level 2',
  'menu.third-level-3': 'Third Level 3',
  'menu.faq': 'Faq',
  'menu.knowledge-base': 'Knowledge Base',
  'menu.authorization': 'Authorization',
  'menu.profile': 'Profile',
  'menu.product': 'Product',
  'menu.miscellaneous': 'Miscellaneous',
  'menu.portfolio': 'Portfolio',
  'menu.social': 'Social',
  'menu.details-alt': 'Details Alt',
  'menu.forms': 'Forms',
  'menu.components': 'Components',
  'menu.layouts': 'Layouts',
  'menu.validations': 'Validations',
  'menu.wizard': 'Wizard',
  'menu.list': 'Lista',
  'menu.create': 'Criar',
  'menu.edit': 'Editar',
  'menu.balance': 'Crédito',
  'menu.distribution': 'Distribuição',
  'menu.balance-sharing': 'Créditos Adicionados',
  'menu.efficiency': 'Eficiência',
  'menu.billing-statement': 'Faturamento Litroz',
  'menu.permissions': 'Permissões',
  'menu.change-password': 'Alterar Senha',
  'menu.password': 'Senha',
  'menu.change': 'Alterar',
  'menu.access': 'Acessos',
  'menu.security': 'Segurança',
  'menu.system': 'Sistema',
  'menu.module': 'Módulo',
  'menu.users': 'Usuários',
  'menu.discountRate': 'Deságios',
  'menu.organization': 'Empresa',
  'menu.litroz': 'Litroz',
  'menu.attachments': 'Anexos',
  'menu.gas-station-billing': 'Repasses',
  'menu.invoices': 'Faturas',
  'menu.fuel-management': 'Tanque Interno',
  'menu.statement': 'Extrato',
  'menu.:id': ':id',
  'menu.purchase': 'Compras',
  'menu.expense-center': 'Centro de Custo',
  'menu.subscriptions': 'Assinaturas',
  'menu.modules': 'Módulos',
  'menu.settings': 'Configurações',
  'menu.plans': 'Planos',
  'menu.payment-history': 'Histórico de Pagamentos',
  'menu.gas-station-list': 'Lista de Postos',

  /* 04.Dashboards */
  'dashboards.pending-orders': 'Pending Orders',
  'dashboards.completed-orders': 'Completed Orders',
  'dashboards.refund-requests': 'Refund Requests',
  'dashboards.new-comments': 'New Comments',
  'dashboards.sales': 'Sales',
  'dashboards.orders': 'Orders',
  'dashboards.refunds': 'Refunds',
  'dashboards.recent-orders': 'Recent Orders',
  'dashboards.product-categories': 'Product Categories',
  'dashboards.cakes': 'Cakes',
  'dashboards.tickets': 'Tickets',
  'dashboards.calendar': 'Calendar',
  'dashboards.best-sellers': 'Best Sellers',
  'dashboards.website-visits': 'Website Visits',
  'dashboards.unique-visitors': 'Unique Visitors',
  'dashboards.this-week': 'This Week',
  'dashboards.last-week': 'Lat Week',
  'dashboards.this-month': 'This Month',
  'dashboards.conversion-rates': 'Conversion Rates',
  'dashboards.per-session': 'Per Session',
  'dashboards.profile-status': 'Profile Status',
  'dashboards.payment-status': 'Payment Status',
  'dashboards.work-progress': 'Work Progress',
  'dashboards.tasks-completed': 'Tasks Completed',
  'dashboards.payments-done': 'Payments Done',
  'dashboards.order-stock': 'Order - Stock',
  'dashboards.categories': 'Categories',
  'dashboards.quick-post': 'Quick Post',
  'dashboards.title': 'Title',
  'dashboards.content': 'Content',
  'dashboards.category': 'Category',
  'dashboards.save-and-publish': 'Save and Publish',
  'dashboards.top-viewed-posts': 'Top Viewed Posts',
  'dashboards.posts': 'Posts',
  'dashboards.pending-for-publish': 'Pending for publish',
  'dashboards.users': 'Users',
  'dashboards.on-approval-process': 'On approval process',
  'dashboards.alerts': 'Alerts',
  'dashboards.waiting-for-notice': 'Waiting for notice',
  'dashboards.files': 'Files',
  'dashboards.pending-for-print': 'Pending for print',
  'dashboards.logs': 'Logs',
  'dashboards.gogo': 'GOGO',
  'dashboards.magic-is-in-the-details': 'MAGIC IS IN THE DETAILS',
  'dashboards.yes-it-is-indeed': 'Yes, it is indeed!',
  'dashboards.advanced-search': 'Advanced Search',
  'dashboards.toppings': 'Toppings',
  'dashboards.type': 'Type',
  'dashboards.keyword': 'Keyword',
  'dashboards.search': 'Search',
  'dashboards.top-rated-items': 'Top Rated Items',
  'dashboards.balance': 'Créditos disponíveis',
  'dashboards.users-balance': 'Créditos distribuídos',
  'dashboards.consumed-balance': 'Créditos utilizados (mês)',
  'dashboards.last-purchase': 'Data última compra',
  'dashboards.total-monthly-transactions': 'Total transacionado bruto',
  'dashboards.total-transactions': 'Total transacionado líquido',
  'dashboards.money-to-receive': 'Valor à receber',
  'dashboards.charged-fee': 'Taxa de operação',

  /* 05.Pages */
  'pages.add-new': 'ADD NEW',
  'pages.add': 'ADICIONAR',
  'pages.add-new-modal-title': 'Novo Item',
  'pages.display-options': 'Filtros',
  'pages.orderby': 'Ordenar por : ',
  'pages.filterby': 'Filtrar por : ',
  'pages.product-name': 'Product Name',
  'pages.license-plate': 'Placa',
  'pages.make': 'Marca',
  'pages.name': 'Nome',
  'pages.surname': 'Sobrenome',
  'pages.cpf': 'CPF',
  'pages.mail': 'E-mail',
  'pages.company': 'Empresa',
  'pages.phone': 'Telefone',
  'pages.pass': 'Senha',
  'pages.confirm-pass': 'Confirmar Senha',
  'pages.app-pass': 'Senha do Aplicativo',
  'pages.transaction-pass': 'Senha da Transação',
  'pages.confirm-transaction-pass': 'Confirmar Senha da Transação',
  'pages.status': 'Status',
  'pages.cnpj': 'CNPJ',
  'pages.fantasy-name': 'Nome Fantasia',
  'pages.social-name': 'Razão Social',
  'pages.apply-charge': 'Aplicar taxa de operação no faturamento',
  'pages.apply-discount': 'Aplicar taxa de desconto no faturamento',
  'pages.cep': 'CEP',
  'pages.website': 'Website',
  'pages.number': 'Número',
  'pages.city': 'Cidade',
  'pages.state': 'Estado',
  'pages.neighborhood': 'Bairro',
  'pages.street': 'Logradouro',
  'pages.model': 'Modelo',
  'pages.year': 'Ano',
  'pages.photo': 'Foto',
  'pages.color': 'Cor',
  'pages.tank': 'Capacidade do tanque (litros)',
  'pages.km': 'Média esperada (km/l)',
  'pages.odometer': 'Odômetro',
  'pages.justification': 'Justificativa',
  'pages.category': 'Categoria',
  'pages.current-balance': 'Saldo Atual',
  'pages.share-balance': 'Compartilhar saldo',
  'pages.current-credit': 'Crédito Atual',
  'pages.share-credit': 'Compartilhar crédito',
  'pages.add-credit': 'Crédito a ser adicionado',
  'pages.add-payment': 'Valor a ser pago',
  'pages.collect-credit': 'Recolher crédito',
  'pages.value': 'Valor',
  'pages.save': 'Salvar',
  'pages.vehicle-type': 'Tipo',
  'pages.fuel': 'Combustível',
  'pages.fuels': 'Combustíveis',
  'pages.cost': 'Preço',
  'pages.liters': 'Litros',
  'pages.total': 'Total',
  'pages.paid': 'Pago',
  'pages.debt': 'Repasse',
  'pages.litroz': 'Litroz',
  'pages.tax-amount': 'Total Líquido',
  'pages.fraud-suspicion': 'Tentativas de Fraude',
  'pages.tax': 'Taxa',
  'pages.of': 'de',
  'pages.to': 'a',
  'pages.station': 'Posto',
  'pages.vehicle': 'Veículo',
  'pages.role': 'Papel',
  'pages.id': 'ID',
  'pages.user': 'Usuário',
  'pages.admin': 'Administrador',
  'pages.date': 'Data',
  'pages.start-date': 'Data Inicial',
  'pages.end-date': 'Data Final',
  'pages.due-date': 'Data Vencimento',
  'pages.created-at': 'Data de criação',
  'pages.payment-date': 'Data de pagamento',
  'pages.billing-date': 'Data de faturamento',
  'pages.date-interval': 'Data',
  'pages.screen': 'Tela',
  'pages.description': 'Descrição',
  'pages.permission': 'Permissão',
  'pages.cancel': 'Cancelar',
  'pages.cancel-transaction': 'Cancelar transação',
  'pages.mark-fraud-as-false-positive': 'Marcar como falso positivo',
  'pages.reap-balance': 'Recolher saldo',
  'pages.reverse-deposit': 'Estornar depósito',
  'pages.confirm': 'Confirmar',
  'pages.see-on-map': 'Ver no mapa',
  'pages.sure-to-delete':
    'Tem certeza que deseja excluir o registro selecionado?',
  'pages.sure-to-collect-credit':
    'Tem certeza que deseja estornar {value} de {name}?',
  'pages.sure-to-cancel-transaction':
    'Deseja realmente cancelar a transação selecionada?',
  'pages.sure-mark-fraud-as-false-positive':
    'Deseja realmente marcar como falso positivo? Essa ação irá desbloquear o veículo para abastecimento.',
  'pages.sure-to-reap-balance':
    'Deseja realmente recolher o saldo da empresa selecionada?',
  'pages.sure-to-reverse-deposit':
    'Deseja realmente estornar o depósito selecionado?',
  'pages.sure-to-change-station':
    'Deseja realmente tornar este posto conveniado?',
  'pages.submit': 'Salvar',
  'pages.delete': 'Deletar',
  'pages.ok': 'OK',
  'pages.ops': 'Ops :(',
  'pages.error': 'Falha na operação. Tente novamente mais tarde.',
  'pages.balance': 'Saldo',
  'pages.undistributed-balance': 'Não distribuído',
  'pages.distributed-balance': 'Distribuído',
  'pages.credit': 'Crédito',
  'pages.another-action': 'Another action',
  'pages.actions': 'Actions',
  'pages.header': 'Header',
  'pages.update': 'ATUALIZAR',
  'pages.export': 'EXPORTAR',
  'pages.filters': 'FILTROS',
  'pages.details': 'Detalhes',
  'pages.orders': 'ORDERS',
  'pages.reprocess': 'REPROCESSAR',
  'pages.rating': 'Rating',
  'pages.price': 'Preço',
  'pages.ingredients': 'Ingredients',
  'pages.is-vegan': 'Is Vegan',
  'pages.order-status': 'Order Status',
  'pages.bake-progress': 'Bake Progress',
  'pages.popularity': 'Popularity',
  'pages.invalid': 'Inválido',
  'pages.comments': 'Comments',
  'pages.error-title': 'Ooops... looks like an error occurred!',
  'pages.error-code': 'Error code',
  'pages.go-back-home': 'GO BACK HOME',
  'pages.mailing-info':
    'Mailing templates use inline styling and table layout to be displayed well at various service providers. To provide better usability we include it as regular html with dangerouslySetInnerHTML.',
  'pages.invoice-info':
    'Invoice template has an inline styled version for usage outside of the project as well as React version.',
  'pages.react-version': 'React Version',
  'pages.inline-version': 'Inline Styled Html Version',
  'pages.like': 'Like',
  'pages.likes': 'Likes',
  'pages.details-title': 'Details',
  'pages.comments-title': 'Comments',
  'pages.questions-title': 'Questions',
  'pages.similar-projects': 'Similar Projects',
  'pages.send': 'Send',
  'pages.addComment': 'Add a comment',
  'pages.location': 'Location',
  'pages.responsibilities': 'Responsibilities',
  'pages.photos': 'Photos',
  'pages.who-to-follow': 'Who to Follow',
  'pages.follow': 'FOLLOW',
  'pages.followers': 'FOLLOWERS',
  'pages.recent-posts': 'Recent Posts',
  'pages.profile': 'PROFILE',
  'pages.friends': 'FRIENDS',
  'pages.images': 'IMAGES',
  'pages.purchase': 'PURCHASE',
  'pages.price.developer': 'DEVELOPER',
  'pages.price.team': 'TEAM',
  'pages.price.enterprise': 'ENTERPRISE',
  'pages.price.twofactorauthentication': 'Two factor authentication',
  'pages.price.teampermissions': 'Team permissions',
  'pages.price.245Support': '24/5 Support',
  'pages.price.247Support': '24/7 Support',
  'pages.price.useractionsauditlog': 'User actions audit log',
  'pages.prices.featurecomparison': 'Feature Comparison',
  'pages.prices.pricecomparison': 'Price Comparison',
  'pages.period': 'Período',
  'pages.enabled-station': 'Habilitado para abastecimento',
  'pages.disabled-station': 'Desabilitado para abastecimento',
  'pages.session-ended':
    'Sua sessão foi encerrada, por favor faça login novamente.',
  'pages.session-ended-title': 'Aviso!',
  'pages.session-ended-button': 'Ok',
  'pages.weekly': 'Semanal',
  'pages.fortnightly': 'Quinzenal',
  'pages.monthly': 'Mensal',
  'pages.driver': 'Condutor',
  'pages.recipient': 'Beneficiário',
  'pages.balance-sharing-report': 'Créditos Adicionados',
  'pages.efficiency': 'Eficiência',
  'pages.charged-fee': 'Taxa de operação',
  'pages.latitude': 'Latitude',
  'pages.longitude': 'Longitude',
  'pages.chargedFee': 'Taxa de operação',
  'pages.discountFee': 'Desconto (%)',
  'pages.transaction-value': 'Total Transação',
  'pages.station-money': 'Total Posto',
  'pages.station-fee': 'Taxa de Operação',
  'pages.revenue': 'Receita',
  'pages.balance-sharing-type': 'Tipo de distribuição de saldo',
  'pages.domain': 'Domínio',
  'pages.access': 'Acesso',
  'pages.password': 'Senha',
  'pages.new-password': 'Nova Senha',
  'pages.confirm-new-password': 'Confirmar nova Senha',
  'pages.states': 'Estado',
  'pages.logout-warning':
    'Após efetuar a troca da senha será realizado o logout automático no sistema, sendo necessário realizar o login novamente com a nova senha.',
  'pages.select-filter': 'Selecionar filtro',
  'pages.permissions': 'Permissões',
  'pages.distribution-type-warning':
    'Ao alterar o tipo de distribuição, todo o saldo distribuido será estornado para empresa. Deseja continuar?',
  'pages.percent': 'Porcentagem',
  'pages.type': 'Tipo',
  'pages.edit': 'Editar',
  'pages.operating-hours': 'Horário de funcionamento',
  'pages.monday': 'Segunda-feira',
  'pages.tuesday': 'Terça-feira',
  'pages.wednesday': 'Quarta-feira',
  'pages.thursday': 'Quinta-feira',
  'pages.friday': 'Sexta-feira',
  'pages.saturday': 'Sábado',
  'pages.sunday': 'Domingo',
  'pages.opens': 'Abertura',
  'pages.closes': 'Fechamento',
  'pages.registration': 'Cadastros',
  'pages.schedules': 'Horários',
  'pages.collection': 'Cobrança',
  'pages.billing': 'Faturamento',
  'pages.frequency': 'Frequência',
  'pages.paymentTerm': 'Prazo de Pagamento (dias)',
  'pages.weekDayReference': 'Dia da Semana de Referência',
  'pages.bank': 'Banco',
  'pages.agency': 'Agência Bancária',
  'pages.account': 'Conta',
  'pages.pixKey': 'Chave Pix',
  'pages.receiptModel': 'Modelo de Recebimento',
  'pages.billingModel': 'Modelo de Faturamento',
  'pages.discountValue': 'Desconto',
  'pages.fullValue': 'Valor sem desconto',
  'pages.organizationGroup': 'Grupo de Empresas',
  'pages.mainOrganization': 'Matriz',
  'pages.branches': 'Filiais',
  'pages.shared-balance': 'Saldo Compartilhado',
  'pages.export-pdf': 'EXPORTAR - PDF',
  'pages.export-excel': 'EXPORTAR - EXCEL',
  'pages.invoices': 'Notas Fiscais',
  'pages.add-archives': 'Adicionar arquivos',
  'pages.size': 'Tamanho',
  'pages.deposit': 'Depósito',
  'pages.invalid-file': 'Arquivo inválido',
  'pages.notes': 'Observações',
  'pages.internalNotes': 'Observações Interna',
  'pages.baseValue': 'Valor base',
  'pages.payment-type-pix': 'PIX',
  'pages.paid-at': 'Data de pagamento',
  'pages.qrCode': 'Código QR',
  'pages.QRCode': 'QR Code',
  'pages.pixCopyAndPaste': 'Pix Copia e Cola',
  'pages.integration': 'Integração',
  'pages.providers': 'Provedores',
  'pages.integration-key': 'Chave de Integração',
  'pages.fueling-image': 'Imagem do abastecimento',
  'pages.code': 'Código',
  'pages.plan': 'Plano',
  'pages.active-subscription': 'Assinatura Ativa',
  'pages.histo': 'Histórico',
  'pages.scheduling': 'Agendamento',
  'pages.to-start': 'A iniciar',
  'pages.path': 'Caminho',
  'pages.system': 'Sistema',
  'pages.module': 'Módulo',
  'pages.label': 'Label',
  'pages.order': 'Ordem de exibição',
  'pages.modules': 'Módulos',
  'pages.chargedFeeAnywhere': 'Taxa de operação - Anywhere',
  'pages.pixPaymentCheckLimit': 'Limite de Checagens PIX por Mês - Anywhere',
  'pages.chargedFeeType': 'Tipo de taxa de operação',
  'pages.reason': 'Motivo',
  'pages.porperty': 'Propriedade',
  'pages.anywhere': 'Litroz Livre',
  'pages.affiliatedChain': 'Rede Afiliada',
  'pages.anywhereChargedFee': 'Taxa Litroz Livre',
  'pages.anywhereChargedFeeType': 'Tipo de Taxa Litroz Livre',
  'pages.tax-receipt': 'Cupom Fiscal',
  'pages.fuel-management': 'Tanque Interno',
  'pages.debit-anywhere-fee': 'Debitar taxa Livre do saldo da empresa',
  'pages.anyhere-receipt': 'Comprovante Litroz Livre',
  'pages.view-location': 'Ver no mapa',
  'pages.credit-provider': 'Financeira',
  'pages.fuel-cost': 'Preço Combustível',
  'pages.last-odometer': 'Ultimo Odômetro',
  'pages.wallet': 'Carteira',
  'pages.discount': 'Desconto',
  'pages.create-transaction': 'Criar Transação',
  'pages.travelled-distance': 'KM Rodado',
  'pages.changing-to-affiliated':
    'Posto de combustível sendo alterado para rede afiliada',
  'pages.already-existis-gas-station': 'Posto de combustível já cadastrado',
  'pages.inactive-gas-station': 'Posto de combustível inativo',

  'bills.weekly': 'Fatura Semanal',
  'bills.fortnightly': 'Fatura Quinzenal',
  'bills.monthly': 'Fatura Mensal',
  'bills.total': 'Total dessa fatura',

  'balanceSharing.total': 'Total distribuido',

  'litroz.more-info': 'Para mais informações entre em contato com a Litroz:',
  'litroz.phone': 'Telefone: (31) 3956-0887',
  'litroz.email': 'contato@litroz.com',
  'litroz.web-site': 'https://www.litroz.com/',
  'litroz.address':
    'Rua dos Otoni, 428 - Santa Efigênia, Belo Horizonte - MG, 30240-310',

  /* 06.Applications */

  /* 06.01.Chat */
  'chat.messages': 'Messages',
  'chat.contacts': 'Contacts',
  'chat.saysomething': 'Say something..',

  /* 06.02.Survey */
  'survey.delete': 'Delete',
  'survey.edit': 'Edit',
  'survey.add-new': 'ADD NEW',
  'survey.add-new-title': 'Add New Survey',
  'survey.title': 'Title',
  'survey.category': 'Category',
  'survey.label': 'Label',
  'survey.status': 'Status',
  'survey.cancel': 'Cancel',
  'survey.submit': 'Submit',
  'survey.another-action': 'Another action',
  'survey.display-options': 'Filtros',
  'survey.orderby': 'Order By : ',
  'survey.all-surveys': 'All Surveys',
  'survey.completed-surveys': 'Completed Surveys',
  'survey.categories': 'Categories',
  'survey.active-surveys': 'Active Surveys',
  'survey.labels': 'Labels',

  /* 06.03.Todo */
  'todo.add-new': 'ADD NEW',
  'todo.add-new-title': 'Add New Todo',
  'todo.title': 'Title',
  'todo.detail': 'Detail',
  'todo.category': 'Category',
  'todo.label': 'Label',
  'todo.status': 'Status',
  'todo.cancel': 'Cancel',
  'todo.submit': 'Submit',
  'todo.action': 'Action',
  'todo.another-action': 'Another action',
  'todo.display-options': 'Filtros',
  'todo.orderby': 'Order By : ',
  'todo.all-tasks': 'All Tasks',
  'todo.pending-tasks': 'Pending Tasks',
  'todo.completed-tasks': 'Completed Tasks',
  'todo.categories': 'Categories',
  'todo.labels': 'Labels',

  /* 07.UI */

  /* 07.01.Alerts */
  'alert.rounded': 'Rounded Alert',
  'alert.react-notifications': 'React Notifications',
  'alert.outline': 'Outline',
  'alert.primary': 'Primary',
  'alert.secondary': 'Secondary',
  'alert.info': 'Info',
  'alert.success': 'Success',
  'alert.warning': 'Warning',
  'alert.error': 'Error',
  'alert.filled': 'Filled',
  'alert.primary-text': 'This is a primary alert—check it out!',
  'alert.secondary-text': 'This is a secondary alert—check it out!',
  'alert.success-text': 'This is a success alert—check it out!',
  'alert.danger-text': 'This is a danger alert—check it out!',
  'alert.warning-text': 'This is a warning alert—check it out!',
  'alert.info-text': 'This is a info alert—check it out!',
  'alert.light-text': 'This is a light alert—check it out!',
  'alert.dark-text': 'This is a dark alert—check it out!',
  'alert.default': 'Default Alert',
  'alert.dismissing': 'Dismissing Alert',
  'alert.dismissing-text':
    'Holy guacamole! You should check in on some of those fields below.',
  'alert.dismissing-without-animate-text':
    'I am an alert and I can be dismissed without animating!',

  /* 07.02.Badges */
  'badge.sizes': 'Sizes',
  'badge.colors': 'Colors',
  'badge.outline': 'Outline',
  'badge.links': 'Links',
  'badge.counter-badges': 'Counter Badges',
  'badge.bootstrap-default': 'Bootstrap Default',
  'badge.primary': 'Primary',
  'badge.secondary': 'Secondary',
  'badge.success': 'Success',
  'badge.danger': 'Danger',
  'badge.warning': 'Warning',
  'badge.info': 'Info',
  'badge.light': 'Light',
  'badge.dark': 'Dark',

  /* 07.03.Buttons */
  'button.default': 'Bootstrap Default',
  'button.colors': 'Colors',
  'button.rounded': 'Rounded',
  'button.outline': 'Outline',
  'button.states': 'States',
  'button.sizes': 'Sizes',
  'button.button-groups': 'Button Groups',
  'button.large-button': 'Large Button',
  'button.small-button': 'Small Button',
  'button.extra-small-button': 'Extra Small Button',
  'button.block-button': 'Block Button',
  'button.active': 'Active',
  'button.disabled': 'Disabled',
  'button.basic': 'Basic',
  'button.toolbar': 'Toolbar',
  'button.nesting': 'Nesting',
  'button.vertical-variation': 'Vertical Variation',
  'button.checkbox-radio-button': 'Checkbox and Radio Button',
  'button.checkbox': 'Checkbox',
  'button.radio': 'Radio',
  'button.radio-button': 'Radio Button',
  'button.primary': 'Primary',
  'button.secondary': 'Secondary',
  'button.success': 'Success',
  'button.danger': 'Danger',
  'button.warning': 'Warning',
  'button.info': 'Info',
  'button.light': 'Light',
  'button.dark': 'Dark',
  'button.states-text':
    'This button shows a spinner for 2 seconds and an error icon for 3 seconds before switching to normal state. This states can be triggered by adding and removing classes.',
  'button.click-here': 'Click Here',
  'button.states-text-alternate':
    'This button shows a spinner for 2 seconds and an error icon for 3 seconds before switching to normal state. This states can be triggered by adding and removing classes.',
  'button.primary-link': 'Primary Link',
  'button.link': 'Link',
  'button.primary-button': 'Primary Button',
  'button.button': 'Button',
  'button.left': 'Left',
  'button.middle': 'Middle',
  'button.right': 'Right',
  'button.dropdown': 'Dropdown',
  'button.dropdown-link': 'Dropdown Link',
  'button.close': 'Fechar',
  'button.create-transaction': 'CRIAR TRANSAÇÃO',

  'dropdowns.notes': 'Observações',
  'dropdowns.make-payments': 'Registrar Pagamento',
  'dropdowns.cancel-payment': 'Cancelar Pagamento',
  'dropdowns.cancel-billing': 'Cancelar Cobrança',
  'dropdowns.transactions': 'Transações',
  'dropdowns.statement': 'Extrato',
  'dropdowns.see-photo-transaction': 'Ver Foto',
  'dropdowns.subscriptions': 'Assinaturas',
  'dropdowns.schedule-billing': 'Agendar Pagamento',
  'dropdowns.cancel-schedule': 'Cancelar Agendamento',
  'dropdowns.payment-history': 'Histórico de Pagamento',
  'dropdowns.modules': 'Módulos',
  'dropdowns.confirm-fraud': 'Confirmar Fraude',
  'dropdowns.mark-as-false-positive': 'Marcar como Falso Positivo',
  'dropdowns.turn-affiliate': 'Tornar Afiliado',

  /* 07.04.Cards */
  'cards.icon-card': 'Icon Card',
  'cards.image-card': 'Image Card',
  'cards.image-overlay-card': 'Image Overlay Card',
  'cards.image-card-list': 'Image Card List',
  'cards.tab-card': 'Tab Card',
  'cards.user-card': 'User Card',

  /* 07.05.Carousel */
  'carousel.basic': 'Carousel Basic',
  'carousel.single': 'Carousel Single',
  'carousel.without-controls': 'Carousel Without Controls',

  /* 07.06.Charts */
  'charts.line': 'Line Chart',
  'charts.polar': 'Polar Chart',
  'charts.area': 'Area Chart',
  'charts.scatter': 'Scatter Chart',
  'charts.bar': 'Bar Chart',
  'charts.radar': 'Radar Chart',
  'charts.pie': 'Pie Chart',
  'charts.doughnut': 'Doughnut Chart',
  'charts.shadow': 'Shadow',
  'charts.no-shadow': 'No Shadow',

  /* 07.07.Collapse */
  'collapse.basic': 'Basic',
  'collapse.toggle': 'Toggle',
  'collapse.accordion': 'Accordion',
  'collapse.controlled': 'Controlled',
  'collapse.uncontrolled': 'Uncontrolled',

  /* 07.08.Dropdowns */
  'dropdowns.basic': 'Basic',
  'dropdowns.controlled': 'Controlled',
  'dropdowns.uncontrolled': 'Uncontrolled',
  'dropdowns.dropdown': 'Dropdown',
  'dropdowns.header': 'Header',
  'dropdowns.action': 'Action',
  'dropdowns.another-action': 'Another Action',
  'dropdowns.right': 'Right',
  'dropdowns.left': 'Left',
  'dropdowns.drop-directions': 'Drop Directions',
  'dropdowns.dropright': 'Dropright',
  'dropdowns.dropleft': 'Dropleft',
  'dropdowns.small-button': 'Small Button',
  'dropdowns.large-button': 'Large Button',
  'dropdowns.sizing': 'Sizing',
  'dropdowns.split-button': 'Split Button Dropdowns',
  'dropdowns.dropup': 'Dropup',

  'dropdowns.edit': 'Editar',
  'dropdowns.remove': 'Remover',
  'dropdowns.efficiency-history': 'Hist. Eficiência',
  'dropdowns.activate': 'Ativar',
  'dropdowns.inactivate': 'Inativar',
  'dropdowns.recover': 'Recuperar',
  'dropdowns.add-balance': 'Adicionar Crédito',
  'dropdowns.add-payment': 'Realizar pagamento',
  'dropdowns.reap-balance': 'Recolher Saldo',
  'dropdowns.cancel': 'Cancelar',
  'dropdowns.reverse': 'Estornar',
  'dropdowns.attachments': 'Anexos',
  'dropdowns.download': 'Download',
  'dropdowns.revoke': 'Revogar',
  'dropdowns.gas-station-list': 'Lista de Postos',

  /* 07.09.Editors */
  'editors.draftjs': 'Draft.js',
  'editors.quill-standart': 'Quill Standard',
  'editors.quill-bubble': 'Quill Bubble',

  /* 07.10.Forms */
  'forms.basic': 'Basic',
  'forms.email': 'E-mail',
  'forms.email-muted': "We'll never share your email with anyone else.",
  'forms.password': 'Password',
  'forms.password-confirm': 'Confirm Password',
  'forms.submit': 'Submit',
  'forms.horizontal': 'Horizontal',
  'forms.radios': 'Radios',
  'forms.first-radio': 'First radio',
  'forms.second-radio': 'Second radio',
  'forms.third-radio-disabled': 'Third disabled radio',
  'forms.checkbox': 'Checkbox',
  'forms.signin': 'Sign in',
  'forms.top-labels-over-line': 'Top Labels Over Line',
  'forms.tags': 'Tags',
  'forms.date': 'Date',
  'forms.state': 'State',
  'forms.top-labels-in-input': 'Top Labels In Input',
  'forms.email-u': 'E-MAIL',
  'forms.password-u': 'PASSWORD',
  'forms.tags-u': 'TAGS',
  'forms.date-u': 'DATE',
  'forms.state-u': 'STATE',
  'forms.grid': 'Form Grid',
  'forms.address': 'Address',
  'forms.address2': 'Address 2',
  'forms.city': 'City',
  'forms.city-message': 'Please select a city!',
  'forms.state-message': 'Please select a state!',
  'forms.zip': 'Zip',
  'forms.signup': 'Signup',
  'forms.inline': 'Inline',
  'forms.validation-availity': 'Availity Reactstrap Validation',
  'forms.validation-formik': 'Formik Validation',
  'forms.default': 'Default',
  'forms.firstname': 'First name',
  'forms.firstname-message': 'Please enter your first name!',
  'forms.lastname': 'Last name',
  'forms.lastname-message': 'Please enter your last name!',
  'forms.name': 'Name',
  'forms.container': 'Recipiente',

  /* 07.11.Form Components */
  'form-components.custom-inputs': 'Custom Inputs',
  'form-components.checkboxes': 'Checkboxes',
  'form-components.radios': 'Radios',
  'form-components.inline': 'Inline',
  'form-components.react-select': 'React Select',
  'form-components.state-single': 'State Single',
  'form-components.state-multiple': 'State Multiple',
  'form-components.react-autosuggest': 'React Autosuggest',
  'form-components.date-picker': 'Date Picker',
  'form-components.date': 'Date',
  'form-components.date-range': 'Date Range',
  'form-components.date-with-time': 'Date with Time',
  'form-components.embedded': 'Embedded',
  'form-components.dropzone': 'Dropzone',
  'form-components.drop-files-here': 'Drop Files Here',
  'form-components.tags': 'Tags',
  'form-components.switch': 'Switch',
  'form-components.primary': 'Primary',
  'form-components.secondary': 'Secondary',
  'form-components.primary-inverse': 'Primary Inverse',
  'form-components.secondary-inverse': 'Secondary Inverse',
  'form-components.slider': 'Slider',
  'form-components.double-slider': 'Double Slider',
  'form-components.single-slider': 'Single Slider',
  'form-components.rating': 'Rating',
  'form-components.interactive': 'Interactive',
  'form-components.readonly': 'Readonly',
  'form-components.type-a-cake': 'Type a Cake',
  'form-components.start': 'Start',
  'form-components.end': 'End',
  'form-components.tables': 'Tables',
  /* 07.12.Icons */
  'icons.simplelineicons': 'Simple Line Icons',
  'icons.iconsmind': 'Iconsmind Icons',

  /* 07.13.Input Groups */
  'input-groups.basic': 'Basic',
  'input-groups.sizing': 'Sizing',
  'input-groups.small': 'Small',
  'input-groups.default': 'Default',
  'input-groups.large': 'Large',
  'input-groups.checkboxes-and-radios': 'Checkboxes and radios',
  'input-groups.multiple-inputs': 'Multiple Inputs',
  'input-groups.first-and-last-name': 'First and last name',
  'input-groups.multiple-addons': 'Multiple Addons',
  'input-groups.button-addons': 'Button Addons',
  'input-groups.button': 'Button',
  'input-groups.buttons-with-dropdowns': 'Buttons with Dropdowns',
  'input-groups.dropdown': 'Dropdown',
  'input-groups.header': 'Header',
  'input-groups.action': 'Action',
  'input-groups.another-action': 'Another Action',
  'input-groups.custom-select': 'Custom Select',
  'input-groups.options': 'Options',
  'input-groups.choose': 'Choose...',
  'input-groups.custom-file-input': 'Custom File Input',

  /* 07.14.Jumbotron */
  'jumbotron.hello-world': 'Hello, world!',
  'jumbotron.lead':
    'This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.',
  'jumbotron.lead-detail':
    'It uses utility classes for typography and spacing to space content out within the larger container.',
  'jumbotron.learn-more': 'Learn more',

  /* 07.15.Modal */
  'modal.basic': 'Basic',
  'modal.modal-title': 'Modal title',
  'modal.launch-demo-modal': 'Launch Demo Modal',
  'modal.scrolling-long-content': 'Scrolling Long Content',
  'modal.backdrop': 'Backdrop',
  'modal.backdrop-value': 'Backdrop value',
  'modal.right-modal': 'Right Modal',
  'modal.launch-right-modal': 'Launch Right Modal',
  'modal.nested-modal': 'Nested Modal',
  'modal.size': 'Size',
  'modal.launch-large-modal': 'Launch Large Modal',
  'modal.launch-small-modal': 'Launch Small Modal',
  'modal.add-new-administrator': 'Adicionar novo administrador',
  'modal.add-new-vehicle': 'Adicionar novo veículo',
  'modal.add-new-module': 'Adicionar novo módulo',
  'modal.add-new-menu': 'Adicionar novo menu',
  'modal.add-new-container': 'Adicionar novo recipiente',
  'modal.add-new-driver': 'Adicionar novo condutor',
  'modal.add-new-recipient': 'Adicionar novo beneficiário',
  'modal.add-balance': 'Adicionar crédito',
  'modal.add-payment': 'Realizar pagamento',
  'modal.edit-administrator': 'Editar administrador',
  'modal.edit-vehicle': 'Editar veículo',
  'modal.edit-module': 'Editar módulo',
  'modal.edit-menu': 'Editar menu',
  'modal.edit-container': 'Editar recipiente',
  'modal.edit-driver': 'Editar condutor',
  'modal.edit-recipient': 'Editar beneficiário',
  'modal.add-branch': 'Adicionar nova filial',
  'modal.edit-branch': 'Editar filial',
  'modal.add-organization': 'Adicionar nova empresa',
  'modal.edit-organization': 'Editar empresa',
  'modal.add-gas-station': 'Adicionar novo posto',
  'modal.edit-gas-station': 'Editar posto',
  'modal.add-user': 'Adicionar novo usuário',
  'modal.edit-user': 'Editar usuário',
  'modal.add-new-permission': 'Adicionar nova permissão',
  'modal.edit-permission': 'Editar permissão',
  'modal.warning': 'Aviso',
  'modal.cancel-transaction': 'Cancelar transação',
  'modal.refund-deposit': 'Estornar depósito',
  'modal.more-filter': 'Mais filtros',
  'modal.add-attachments': 'Adicionar anexos',
  'modal.add-notes': 'Adicionar observações',
  'modal.sure-to-make-payment': 'Tem certeza que deseja registrar o pagamento?',
  'modal.sure-to-cancel-payment':
    'Tem certeza que deseja cancelar o pagamento?',
  'modal.sure-to-cancel-billing': 'Tem certeza que deseja cancelar a cobrança?',
  'modal.reprocess-transfers': 'Reprocessar repasses',
  'modal.select-date-range': 'Necessário selecionar o intervalo de datas.',
  'modal.equal-date-range': 'Intervalo de datas deve ser igual.',
  'modal.add-plan': 'Adicionar Assinatura',
  'modal.edit-plan': 'Editar Assinatura',
  'modal.subscription-conflict':
    'Já existe uma assinatura ativa no período selecionado. Altere a data de início da nova assinatura ou revogue/altere a data de fim da assinatura vigente.',
  'modal.subscription-invalid-date':
    'A data de início não pode ser maior que a data de fim.',
  'modal.module-conflict': 'Já existe um módulo ativo no período selecionado.',
  'modal.confirmation': 'Posto já cadastrado na rede Litroz Livre',
  'modal.conflict': 'Posto já cadastrado',
  'modal.inactive': 'Posto inativo',
  /* 07.16.Navigation */
  'nav.basic': 'Nav Basic',
  'nav.active': 'Active',
  'nav.disabled': 'Disabled',
  'nav.disabled-link': 'Disabled Link',
  'nav.link': 'Link',
  'nav.longer-link': 'Longer nav link',
  'nav.another-link': 'Another Link',
  'nav.right': 'Right',
  'nav.dropdown': 'Dropdown',
  'nav.header': 'Header',
  'nav.action': 'Action',
  'nav.another-action': 'Another Action',
  'nav.horizontal-alignment': 'Nav Horizontal Alignment',
  'nav.vertical-alignment': 'Nav Vertical Alignment',
  'nav.pills': 'Nav Pills',
  'nav.fill-justify': 'Nav Fill and Justify',
  'nav.pills-dropdowns': 'Nav Pills with Dropdowns',
  'nav.pagination-basic': 'Pagination Basic',
  'nav.pagination-sizing': 'Pagination Sizing',
  'nav.large': 'Large',
  'nav.small': 'Small',
  'nav.center': 'Center',
  'nav.pagination-alignment': 'Pagination Alignment',
  'nav.breadcrumb-basic': 'Breadcrumb Basic',

  /* 07.17.Popover & Tooltip */
  'popover-tooltip.popover': 'Popover',
  'popover-tooltip.tooltip': 'Tooltip',

  /* 07.18.Sortable */
  'sortable.columns': 'Sorting Columns',
  'sortable.basic': 'Basic',
  'sortable.handles': 'Handles',

  /* 07.19.Maps */
  'maps.google': 'Google',
  'maps.yandex': 'Yandex',

  /* 07.20.Tables */
  'table.bootstrap-tables': 'Bootstrap Tables',
  'table.bootstrap-basic': 'Basic Table',
  'table.bootstrap-striped': 'Striped Rows',
  'table.bootstrap-bordered': 'Bordered Table',
  'table.bootstrap-borderless': 'Borderless Table',
  'table.bootstrap-hoverable': 'Hoverable Rows',
  'table.bootstrap-responsive': 'Responsive Table',
  'table.react-tables': 'React Tables',
  'table.react-pagination': 'Pagination',
  'table.react-scrollable': 'Scrollable',
  'table.react-advanced': 'Filter, Length and Jump',

  /* 07.21.Wizards */
  'wizard.step-name-1': 'Step 1',
  'wizard.step-name-2': 'Step 2',
  'wizard.step-name-3': 'Step 3',
  'wizard.step-desc-1': 'First step description',
  'wizard.step-desc-2': 'Second step description',
  'wizard.step-desc-3': 'Third step description',
  'wizard.content-1': 'Step content for first step.',
  'wizard.content-2': 'Step content for second step.',
  'wizard.content-3': 'Last step content!',
  'wizard.content-thanks': 'Thank You!',
  'wizard.next': 'Next',
  'wizard.prev': 'Back',
  'wizard.registered': 'Your registration completed successfully!',
  'wizard.async': 'Async save for 3 seconds!',

  'organizations.aggregate': 'Agregado',
  'date.range': 'Intervalo de datas',
  'api.error': 'Não foi possível carregar estes dados',
  'api.empty': 'Não foram encontrados dados para serem exibidos',

  'fuel.ethanol': 'Etanol',
  'fuel.premiumEthanol': 'Etanol Aditivado',
  'fuel.gas': 'Gasolina',
  'fuel.premiumGas': 'Gasolina Aditivada',
  'fuel.carrierNaturalGas': 'Gás Natural Veicular',
  'fuel.diesel': 'Diesel S10',
  'fuel.dieselS500': 'Diesel S500',
  'fuel.flex': 'Flex',
  'fuel.arla': 'Arla',

  'month-year.1': 'Janeiro {year}',
  'month-year.2': 'Fevereiro {year}',
  'month-year.3': 'Março {year}',
  'month-year.4': 'Abril {year}',
  'month-year.5': 'Maio {year}',
  'month-year.6': 'Junho {year}',
  'month-year.7': 'Julho {year}',
  'month-year.8': 'Agosto {year}',
  'month-year.9': 'Setembro {year}',
  'month-year.10': 'Outubro {year}',
  'month-year.11': 'Novembro {year}',
  'month-year.12': 'Dezembro {year}',

  'month.1': 'Janeiro',
  'month.2': 'Fevereiro',
  'month.3': 'Março',
  'month.4': 'Abril',
  'month.5': 'Maio',
  'month.6': 'Junho',
  'month.7': 'Julho',
  'month.8': 'Agosto',
  'month.9': 'Setembro',
  'month.10': 'Outubro',
  'month.11': 'Novembro',
  'month.12': 'Dezembro',

  'transaction.file-name': 'Transações',
  'organization.file-name': 'Empresas',
  'station.file-name': 'Postos',
  'transaction.success': 'Sucesso',
  'transaction.failure': 'Falha',
  'deposits.file-name': 'Depósitos',
  'vehicles.file-name': 'Veículos',
  'admins.file-name': 'Administradores',
  'users.file-name': 'Usuários',
  'permissions.file-name': 'Permissões',
  'litroz-billing.file-name': 'Faturamento Litroz',
  'discount.file-name': 'Deságios',
  'station-users.file-name': 'Usuários de Postos',
  'balanceSharing.file-name': 'Créditos Adicionados',
  'invoice.file-name': 'Fatura',
  'user-statements.file-name': 'Extrato de Usuário',
  'vehicle-statements.file-name': 'Extrato de Veículo',
  'driver.file-name': 'condutores',
  'moneyTransfer.file-name': 'Pagamentos Efetuados',
  'purchase.file-name': 'Compras',

  /* 08. Entries */

  /* 08.01.Driver */
  'driver.firstName': 'Nome',
  'driver.lastName': 'Sobrenome',
  'driver.identifier': 'CPF',
  'driver.email': 'E-mail',
  'driver.phone': 'Telefone',
  'driver.driversLicense': 'N° Registro CNH',
  'driver.driversLicenseExpirationDate': 'Data validade CNH',
  'driver.password': 'Senha de aplicativo',
  'driver.authenticateTransaction': 'Senha de transação',

  'user.category.employee': 'Condutor',
  'user.category.contractor': 'Beneficiário',

  'distribution.title': 'Distribuição de saldo',
  'distribution.available': 'Saldo disponível: {balance}',

  'data.approved': 'Aprovada',
  'data.pending': 'Pendente',
  'data.confirmed': 'Confirmado',
  'data.falsely-flagged': 'Falsamente sinalizado',
  'data.rejected': 'Rejeitada',
  'data.canceled': 'Cancelada',
  'data.paid': 'Pago',
  'data.cancelled': 'Cancelada',
  'data.refuelDisabled': 'Abastecimento desabilitado',
  'data.refuelEnabled': 'Abastecimento habilitado',
  'data.active': 'Ativo',
  'data.inactive': 'Inativo',
  'data.yes': 'Sim',
  'data.no': 'Não',
  'data.device-detected-during-refueling':
    'Dispositivo detectado durante abastecimento',

  'column.date': 'Data',
  'column.balance': 'Saldo',
  'column.total': 'Total',
  'column.price': 'Preço',
  'column.liters': 'Litros',
  'column.transaction-value': 'Total Transação',
  'column.station-money': 'Total Posto',
  'column.revenue': 'Receita',
  'column.value': 'Valor',

  'input.employee': 'Empregado',
  'input.vehicle': 'Veículo',
  'input.add-description': 'Adicionar descrição',

  'domain.admin': 'Portal do Administrador',
  'domain.portal': 'Portal do Cliente',
  'domain.gas-stations': 'Portal do Posto',

  'permission.remove': 'Exclusão',
  'permission.list': 'Leitura',
  'permission.add': 'Gravação',

  'period.7days': '7 dias',
  'period.15days': '15 dias',
  'period.30days': '30 dias',
  'period.24hours': '24 horas',
  'period.3months': '3 meses',
  'period.6months': '6 meses',
  'period.12months': '12 meses',
  'period.24months': '24 meses',

  'model.prepaid': 'Pré-pago',
  'model.postpaid': 'Pós-pago',

  'credit-provider.litroz': 'Litroz',
  'credit-provider.arbi': 'Arbi',

  'password.rule.minimum-characters': 'Minimo 8 caracteres',
  'password.rule.uppercase-lowercase-letters': 'Letras maiúsculas e minúsculas',
  'password.rule.alphanumeric': 'Letras e números',
  'password.rule.match': 'Senhas não coincidem',

  'error.access-deined': 'Acesso negado',
  'error.access-deined-text':
    'Você não possui permissão acesso a essa tela. Entre em contato com o administrador pra solicitar permissão.',
  'error.aboveCapacity': 'Excedeu a capacidade do tanque do veículo',
  'error.aboveEfficiency':
    'Abastecimento abaixo da eficiência mínima esperada ou acima da eficiência máxima esperada',
  'error.aboveLimit': 'Excedeu o limite de abastecimento',
  'error.insufficientBalance': 'Saldo insuficiente',
  'error.incorrectPassword': 'Senha de transação incorreta',
  'error.generalRejection': 'Erro ao processar a transação',
};

/* 02.User Login, Logout, Register */
// 'user.login-title': 'Login',
// 'user.register': 'Cadastrar',
// 'user.forgot-password': 'Esqueci minha senha',
// 'user.email': 'E-mail',
// 'user.password': 'Senha',
// 'user.forgot-password-question': 'Esqueceu sua senha?',
// 'user.fullname': 'Full Name',
// 'user.login-button': 'ENTRAR',
// 'user.register-button': 'REGISTER',
// 'user.reset-password-button': 'RESET',
// 'user.buy': 'BUY',
// 'user.username': 'Usuário',
